import React, { useState, useCallback, useRef } from 'react';
import './App.css';

const DKK = 100;
const EUR = 743.78;
const USD = 706.66;

const sanitize = (value) => {
  const fixedForDecimal = value.toString().replace(",", ".");
  return fixedForDecimal;
}

const convert = (value, from, to) => {
  return ((value * from) / to).toFixed(2);
}

function App() {
  const [usd, setUsd] = useState(0)
  const [dkk, setDkk] = useState(0)
  const [eur, setEur] = useState(0)
  const usdField = useRef(null);
  const eurField = useRef(null);
  const dkkField = useRef(null);

  const updateValues = useCallback((value, currency) => {
    if (currency != USD) {
      setUsd(convert(value, currency, USD));
    }
    if (currency != EUR) {
      setEur(convert(value, currency, EUR));
    }
    if (currency != DKK) {
      setDkk(convert(value, currency, DKK))
    }
  }, [setDkk, setEur, setUsd]);

  const onUsdInputChanged = useCallback((e) => {
    const newValue = sanitize(e.target.value);
    setUsd(newValue)
    updateValues(newValue, USD);
  }, [setUsd, updateValues]);

  const onDkkInputChanged = useCallback((e) => {
    const newValue = sanitize(e.target.value);
    setDkk(newValue);
    updateValues(newValue, DKK);
  }, [setDkk, updateValues]);

  const onEurInputChanged = useCallback((e) => {
    const newValue = sanitize(e.target.value);
    setEur(newValue);
    updateValues(newValue, EUR);
  }, [setEur, updateValues]);

  const onClickClearUsd = useCallback(() => {
    setUsd('')
    updateValues(0, USD);
    usdField.current.focus();
  },[setUsd, updateValues, usdField]);

  const onClickClearEur = useCallback(() => {
    setEur('')
    updateValues(0, EUR);
    eurField.current.focus();
  },[setEur, updateValues, eurField]);

  const onClickClearDkk = useCallback(() => {
    setDkk('')
    updateValues(0, DKK);
    dkkField.current.focus();
  },[setDkk, updateValues, dkkField]);

  return (
    <div className="App">
      <div className="currency">
        <label for="usd">USD </label>
        <input
          id="usd"
          inputMode="decimal"
          value={usd}
          onChange={onUsdInputChanged} 
          ref={usdField}
          />
        <button onClick={onClickClearUsd}>X</button>
      </div>
      <div className="currency">
        <label for="dkk">DKK </label>
        <input
          id="dkk"
          inputMode="decimal"
          value={dkk}
          onChange={onDkkInputChanged} 
          ref={dkkField}
          />
        <button onClick={onClickClearDkk}>X</button>
      </div>
      <div className="currency">
        <label for="eur">EUR </label>
        <input
          id="eur"
          inputMode="decimal"
          value={eur}
          onChange={onEurInputChanged} 
          ref={eurField}
          />
        <button onClick={onClickClearEur}>X</button>
      </div>
    </div>
  );
}

export default App;
